<template>
  <v-container class="pa-0 app-fill-height mobile-container">
    <v-container tag="section" class="pa-0 mb-10 mobile-container">
      <v-responsive max-width="800px" class="mx-auto">
          <v-card class="page-box-wrapper elevation-3">
            <div class="page-box-content">
              <h2 class="h2-green">
                Набор <br />
                "Обмен веществ"
              </h2>
              <div class="img-top-box">
                <img
                  src="/img/made-in-germany.png"
                  alt="Made in Germany"
                  class="germany-top"
                />
                <div class="text-center">
                  <img
                    src="/img/metabolism/metabolism-honey-peach.jpg"
                    alt="Набор Обмен веществ"
                    class="metabolism-honey-peach"
                  />
                </div>
              </div>
              <div class="p-main">
                <h3>Набор «Обмен веществ» – ключ к энергии и здоровью</h3>
                <p>
                  Обмен веществ, или метаболизм – это основа работы организма.
                  Он отвечает за энергию, восстановление клеток, работу гормонов
                  и иммунитета. Когда всё в балансе – ты здоров и чувствуешь силу,
                  лёгкость, ясность ума, твой вес в норме. Но если процессы
                  замедляются – появляются проблемы с пищеварением, лишний вес, ухудшается состояние кожи, нарушается сон, возникает усталость.
                </p>
                <p class="mt-3">
                  Метаболизм – это двигатель твоего тела. И важно, чтобы он
                  работал без сбоев!
                </p>
                <p class="mt-3">
                  Поскольку организм – это единая система, поддерживать его
                  нужно комплексно. Именно поэтому в наборе «Обмен веществ»
                  собраны продукты, которые усиливают друг друга и помогают
                  организму работать слаженно.
                </p>
                <!-- <div class="text-center mt-5">
                  <iframe
                    src="https://fast.wistia.net/embed/iframe/blddc6npia?seo=false&videoFoam=true"
                    title="С. Зверева Обмен веществ"
                    allow="autoplay; fullscreen"
                    allowtransparency="true"
                    frameborder="0"
                    scrolling="no"
                    class="wistia_embed"
                    name="wistia_embed"
                    msallowfullscreen
                    width="280"
                    height="400"
                  ></iframe>
                </div> -->
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Синергия – как продукты усиливают друг друга
                  </h2>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="gel-avatar">
                        <img src="/img/metabolism/gel-avatar.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">
                          Гель Алоэ Вера – активатор усвоения и восстановление
                        </h3>
                        <p class="p-col">
                          Обеспечивает организм ферментами, аминокислотами и
                          антиоксидантами, улучшает усвоение всех нутриентов.
                        </p>
                      </div>
                    </v-col>
                    <div class="synergy-box-mobile">
                      <img src="/img/metabolism/synergy.png" alt="" />
                    </div>
                    <v-col cols="12" sm="6">
                      <div class="reishi-omega-avatar">
                        <img
                          src="/img/metabolism/reishi-omega-avatar.png"
                          alt=""
                        />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">
                          Супер Омега + Рейши Плюс – баланс жиров и углеводов,
                          детокс
                        </h3>
                        <p class="p-col">
                          Регулируют углеводный и липидный обмен, поддерживают
                          работу сердца и сосудов. Рейши обладает
                          детокс-эффектом и укрепляет иммунитет.
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="synergy-box">
                    <img src="/img/metabolism/synergy.png" alt="" />
                  </div>
                  <div class="synergy-box-mobile">
                    <img src="/img/metabolism/synergy.png" alt="" />
                  </div>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="pro-balance-avatar">
                        <img
                          src="/img/metabolism/pro-balance-avatar.png"
                          alt=""
                        />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">
                          Про Баланс – контроль кислотно-щелочного равновесия
                        </h3>
                        <p class="p-col">
                          Поддерживает оптимальный pH, что необходимо для
                          нормального метаболизма и работы ферментов.
                        </p>
                      </div>
                    </v-col>
                    <div class="synergy-box-mobile">
                      <img src="/img/metabolism/synergy.png" alt="" />
                    </div>
                    <v-col cols="12" sm="6">
                      <div class="pro12-avatar">
                        <img src="/img/metabolism/pro12-avatar.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">
                          Про 12 – здоровье кишечника и очищение
                        </h3>
                        <p class="p-col">
                          Восстанавливает микрофлору и поддерживают естественные
                          процессы детоксикации.
                        </p>
                      </div>
                    </v-col>
                  </v-row>

                  <p class="mt-3">
                    Этот комплекс даёт организму всё, что нужно для стабильного
                    обмена веществ, энергии и здоровья на клеточном уровне.
                  </p>
                  <p class="mt-3">
                    Не заставляй свой организм работать в режиме выживания – дай
                    ему то, что нужно, и почувствуй разницу.
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Питьевой гель Алоэ Вера – природная энергия и защита
                  </h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/aloe-leaf-iasc-mini.png"
                        alt=""
                        class="aloe-leaf-iasc"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <h3 class="h3-green">Три ключа к здоровью</h3>
                      <p class="mt-3">
                        <span class="title-orange">+ ПИТАНИЕ:</span>
                        насыщает организм витаминами, минералами и
                        аминокислотами, улучшает их усвоение.
                      </p>
                      <p class="mt-3">
                        <span class="title-orange">+ ЗАЩИТА:</span>
                        стимулирует выработку коллагена, поддерживает суставы,
                        кожу и иммунную систему.
                      </p>
                      <p class="mt-3">
                        <span class="title-orange">+ ОБМЕН ВЕЩЕСТВ:</span>
                        ускоряет энергетический обмен, помогая организму
                        эффективно преобразовывать питательные вещества в
                        энергию.
                      </p>
                    </v-col>
                  </v-row>
                  <p class="mt-5">
                    <strong>Гель Алоэ Вера</strong> - активатор усвоения. Научно
                    доказано, что он повышает биодоступность всех микронутриентов, в частности, витаминов C и E
                    более чем на 200%, усиливая их эффект.
                  </p>
                  <p class="mt-3">
                    <strong>Чем он уникален?</strong>
                  </p>
                  <ul class="mt-3 pl-5">
                    <li>
                      200+ биоактивных соединений, включая витамины, минералы,
                      аминокислоты, ферменты, полисахариды.
                    </li>
                    <li>
                      Природный адаптоген – помогает организму адаптироваться к
                      стрессу.
                    </li>
                    <li>
                      Мягкий детокс – способствует естественному очищению
                      организма.
                    </li>
                  </ul>
                  <h4 class="h4-title mt-5 mb-3">Вкус на выбор</h4>
                  <v-row class="mb-4">
                    <v-col cols="2" class="text-center">
                      <img
                        src="/img/products/av-honey.png"
                        alt=""
                        class="av-honey"
                      />
                    </v-col>
                    <v-col cols="10">
                      <h4 class="h4-honey">
                        Питьевой гель Алоэ Вера с мёдом – укрепление иммунитета
                        и жизненная энергия
                      </h4>
                      <p class="mt-3">
                        Этот вариант объединяет целебную силу Алоэ Вера и
                        натурального цветочного мёда. Он помогает организму
                        быстрее восстанавливаться, поддерживает иммунитет и
                        улучшает пищеварение.
                      </p>
                      <p class="mt-3">
                        Состав: 90% листового геля Алоэ Вера, натуральный мёд,
                        витамин С (60 мг – 100% суточной нормы).
                      </p>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <strong>Чем он полезен?</strong>
                  </p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Омолаживает и обновляет клетки – благодаря антиоксидантам
                      и питательным веществам.
                    </li>
                    <li>
                      Укрепляет иммунитет – сочетание Алоэ Вера, мёда и витамина
                      С активизирует защитные силы организма.
                    </li>
                    <li>
                      Способствует мягкому очищению организма – помогает вывести
                      токсины и поддерживает здоровье печени.
                    </li>
                    <li>
                      Стимулирует пищеварение и обмен веществ – содержит
                      ферменты, которые помогают организму лучше усваивать пищу.
                    </li>
                  </ul>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Больше энергии и бодрости – организм работает без
                      перегрузки, без упадков сил.
                    </li>
                    <li>
                      Крепкий иммунитет – меньше подверженности вирусам и
                      инфекциям.
                    </li>
                    <li>
                      Комфортное пищеварение – без тяжести и дискомфорта после
                      еды.
                    </li>
                    <li>
                      Поддержку молодости и красоты – здоровая кожа,
                      эластичность суставов, лёгкость в теле и отличное настроение.
                    </li>
                  </ul>
                  <p class="mt-3 font-weight-bold">
                    Естественная сила природы – для здоровья и долголетия!
                  </p>
                  <!-- <v-row class="mt-7">
                    <v-col cols="2" class="text-center">
                      <img
                        src="/img/metabolism/aloe-leaf.png"
                        alt=""
                        class="av-leaf-avatar"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>
                        Содержит такие биоактивные вещества как ацеманнан и
                        легнины, которые известны своим благотворным влиянием на
                        метаболизм организма. Кроме того, мед содержит ферменты,
                        способствующие пищеварению, а также доказано его
                        мочегонное и холестеринснижающее действие.
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-center">
                      <img
                        src="/img/metabolism/honey.png"
                        alt=""
                        class="honey-avatar"
                      />
                    </v-col>
                    <v-col cols="10">
                      <p>
                        Авиценна писал, что мед придает бодрость, способствует
                        пищеварению, возбуждает аппетит, сохраняет молодость,
                        восстанавливает память. Древняя китайская фармакопея
                        расценивает мед как средство для омоложения и продления
                        жизни. Доказано холестеринснижающее, мочегонное действие
                        меда.
                      </p>
                    </v-col>
                  </v-row> -->
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/9ml0dps32s?web_component=true&seo=true" title="Дадали о геле Алоэ Вера мёд Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <v-row class="mt-7">
                    <v-col cols="2" class="text-center">
                      <img
                        src="/img/products/av-peach.png"
                        alt=""
                        class="av-peach"
                      />
                    </v-col>
                    <v-col cols="10">
                      <h4 class="h4-peach">
                        Питьевой гель Алоэ Вера со вкусом Персик – поддержка ЖКТ
                        и лёгкость каждый день
                      </h4>
                      <p class="mt-3">
                        Этот вариант создан специально для комфортного
                        пищеварения. В нём нет добавленного сахара, поэтому он
                        идеально подходит тем, кто следит за фигурой,
                        придерживается здорового питания и даже диабетикам.
                      </p>
                      <p class="mt-3">
                        Состав: 98% листового геля Алоэ Вера, инулин, витамин С
                        (80 мг – 100% суточной нормы).
                      </p>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <strong>Чем он полезен?</strong>
                  </p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Очищает и восстанавливает ЖКТ – помогает кишечнику
                      работать мягко и естественно.
                    </li>
                    <li>
                      Улучшает усвоение питательных веществ – делает питание
                      более эффективным.
                    </li>
                    <li>
                      Содержит инулин – натуральный пребиотик – питает полезную
                      микрофлору, улучшает пищеварение и поддерживает иммунитет.
                    </li>
                    <li>
                      Обладает адаптогенным, антиоксидантным и заживляющим
                      действием – защищает организм от стресса и воспалений.
                    </li>
                  </ul>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Лёгкость и комфорт в желудке – пищеварение работает без
                      перегрузки.
                    </li>
                    <li>
                      Больше пользы от пищи и витаминов – организм усваивает
                      максимум.
                    </li>
                    <li>
                      Крепкий иммунитет и защиту от воспалений – здоровье
                      начинается с кишечника.
                    </li>
                    <li>
                      Нормализацию сахара и поддержку фигуры – без резких
                      скачков энергии и чувства голода.
                    </li>
                  </ul>
                  <p class="mt-3 font-weight-bold">
                    Забота о кишечнике – основа твоего самочувствия!
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/hq91dc2kz5?web_component=true&seo=true" title="Гель АВ со вкусом Персика Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Гриб Рейши – мощная поддержка печени, иммунитета и обмена
                    веществ
                  </h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/reishi.png"
                        alt=""
                        class="reishi-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p>
                        Гриб Рейши известен как «гриб бессмертия» благодаря
                        своему комплексному воздействию на организм. Он
                        поддерживает работу печени и её детоксикационные
                        функции, защищает от свободных радикалов и способствует
                        нормализации обмена веществ.
                      </p>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <strong>Чем он полезен?</strong>
                  </p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Очищает и защищает печень – способствует детоксикации,
                      помогает печени работать более эффективно.
                    </li>
                    <li>
                      Обладает антиоксидантным и противовоспалительным действием
                      – защищает клетки от повреждений.
                    </li>
                    <li>
                      Поддерживает сердечно-сосудистую систему – помогает
                      регулировать уровень холестерина и кровяное давление.
                    </li>
                    <li>
                      Укрепляет иммунитет – активизирует защитные механизмы
                      организма.
                    </li>
                  </ul>
                  <p class="mt-3">
                    Работает в синергии с Супер Омегой, помогая организму
                    регулировать липидный обмен, снижать воспаление и
                    поддерживать баланс жиров в организме.
                  </p>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Чистую и здоровую печень – эффективное очищение организма
                      от токсинов.
                    </li>
                    <li>
                      Больше энергии и сил – обмен веществ протекает гармонично.
                    </li>
                    <li>
                      Стабильный уровень холестерина – защита сердца и сосудов.
                    </li>
                    <li>
                      Крепкий иммунитет – организм легче справляется с вирусами
                      и воспалениями.
                    </li>
                  </ul>
                  <p class="mt-3 mb-10 font-weight-bold">
                    Гармония здоровья – в каждой порции!
                  </p>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/anti-oxidant.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Нормализация обмена веществ</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/liver-protection.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Защита печени</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/protection.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Защита от свободных радикалов</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/anti-inflammatory.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Противовоспалительное действие</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/immunomodulators.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Иммуномодулирующее действие</p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="4" class="text-center">
                      <img
                        src="/img/metabolism/reishi.png"
                        alt=""
                        class="img-avatar"
                      />
                    </v-col>
                    <v-col cols="12" sm="8">
                      <p>
                        Результаты исследований свидетельствуют о том, что гриб
                        Рейши оказывает потенциальное гиполипидемическое
                        действие, а его возможный механизм связан со снижением
                        окислительного стресса и воспалительной реакции,
                        модулированием синтеза желчных кислот и факторов
                        регуляции липидов, а также способствовании обратному
                        транспорту холестерина.
                      </p>
                    </v-col>
                  </v-row>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/sqz030g7ty?web_component=true&seo=true" title="Профессор Далали В.А. о грибе Рейши Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    ПРО 12 – синбиотик для здоровья кишечника и обмена веществ
                  </h2>
                  <p class="mt-5">
                    Современный ритм жизни – стрессы, несбалансированное
                    питание, приём лекарств, загрязнённая экология – нарушает
                    хрупкий баланс микробиоты кишечника. А значит, страдает и
                    обмен веществ.
                  </p>
                  <p class="mt-3">
                    ПРО 12 – это синбиотик нового поколения, который помогает
                    восстановить баланс кишечной микрофлоры и защитить
                    пищеварительную систему.
                  </p>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/pro12.png"
                        alt=""
                        class="pro12-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <h3 class="h3-green">Чем он полезен?</h3>
                      <ul class="ul-check">
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          Восстанавливает микрофлору – содержит 1 млрд бактерий
                          из 12 штаммов + пребиотик для их питания.
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          Участвует в обмене веществ – микробиота помогает
                          расщеплять белки, жиры, углеводы и синтезировать
                          витамины.
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          Поддерживает детоксикацию организма – участвует в
                          рециркуляции желчных кислот, выводит токсины.
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          Способствует синтезу короткоцепочечных жирных кислот
                          (КЦЖК) – эти соединения питают клетки кишечника и
                          улучшают обмен веществ.
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="4" class="text-center">
                      <img
                        src="/img/metabolism/intestinal-health.jpg"
                        alt=""
                        class="img-avatar"
                      />
                    </v-col>
                    <v-col cols="12" sm="8">
                      <p class="mb-3">
                        Запатентованная двойная инкапсуляция защищает бактерии
                        от разрушения в желудке, гарантируя их доставку в
                        кишечник.
                      </p>
                      <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                      <ul class="mt-3 pl-5">
                        <li>
                          Здоровую микрофлору – кишечник работает как часы.
                        </li>
                        <li>
                          Лёгкость в животе и комфортное пищеварение – без
                          вздутия и тяжести.
                        </li>
                        <li>
                          Сильный иммунитет – 80% защитных функций организма
                          зависит от состояния кишечника.
                        </li>
                        <li>
                          Лучший обмен веществ и энергию – нутриенты усваиваются
                          эффективнее.
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <p class="mt-3 font-weight-bold">
                    ПРО 12 – простая и эффективная забота о кишечнике!
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/c11mmob7ui?web_component=true&seo=true" title="Про 12 Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <h2 class="h2-title-bg mt-7 mb-5">
                    Положительные эффекты КЦЖК
                  </h2>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/bifidobacteria.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Стимуляция роста бифидобактерий</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/pathogens.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Подавление патогенов</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/toxic.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Снижение уровня токсических метаболитов и канцерогенов
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/body-energy-supply.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Энергообеспечение организма</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/normalization-gastric.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Нормализация моторики ЖКТ</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/microelements.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Улучшение всасывания Na, K, Ca, Mg, Zn</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/liver-protection.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Усиление гликогенеза в печени</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/anti-inflammatory.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Противовоспалительное действие</p>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Про Баланс – гармония кислотно-щелочного равновесия и
                    метаболизма
                  </h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/pro-balance.png"
                        alt=""
                        class="img-pro-balance"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p>
                        Обмен веществ – это тысячи биохимических реакций,
                        которые происходят в организме ежесекундно. Чтобы
                        ферменты, регулирующие эти процессы, работали
                        эффективно, им необходима сбалансированная среда –
                        правильное соотношение кислот и щелочей.
                      </p>
                      <p class="mt-3">
                        Когда кислотно-щелочной баланс нарушен, организм
                        испытывает перегрузку, что может привести к замедлению
                        метаболизма, накоплению токсинов и, как следствие, лишнего веса, хронической
                        усталости и даже воспалениям.
                      </p>
                      <p class="mt-3">
                        Про Баланс – это комплекс минералов, который помогает
                        поддерживать pH-баланс и улучшает обмен веществ на
                        клеточном уровне.
                      </p>
                    </v-col>
                  </v-row>
                  <p class="mt-3">
                    <strong>Чем он полезен?</strong>
                  </p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Восстанавливает кислотно-щелочной баланс – создаёт
                      оптимальную среду для работы ферментов.
                    </li>
                    <li>
                      Способствует нормализации липидного, углеводного и
                      белкового обмена – помогает поддерживать стабильный вес и нормальный
                      метаболизма.
                    </li>
                    <li>
                      Участвует в энергетическом обмене – помогает организму
                      эффективно преобразовывать пищу в энергию.
                    </li>
                    <li>
                      Поддерживает буферные системы организма – помогает быстрее
                      адаптироваться к изменениям и стрессу.
                    </li>
                  </ul>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Больше энергии и меньше усталости – клетки получают
                      питание и работают эффективнее.
                    </li>
                    <li>
                      Стабильный обмен веществ – поддержание нормального уровня сахара крови, холестерина
                      и жировых отложений.
                    </li>
                    <li>
                      Быстрое восстановление кислотно-щелочного баланса –
                      организм работает в оптимальном режиме.
                    </li>
                    <li>
                      Профилактику инсулинорезистентности и метаболических
                      нарушений – микроэлементы помогают  поддерживать гормональный фон на оптимальном уровне.
                    </li>
                  </ul>
                  <p class="mt-3 mb-10 font-weight-bold">
                    Про Баланс – твой путь к гармонии внутри!
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/e1mppkr7sh?web_component=true&seo=true" title="Про Баланс Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <!-- <p class="mt-7">
                    Употребление минералов помогает поддерживать
                    кислотно-щелочной баланс, а также работоспособность буферных
                    систем. В случае нарушения pH минеральные вещества помогают
                    организму быстрее вернуться в равновесие.
                  </p> -->
                  <!-- <p class="mt-3 mb-5">
                    Доказано, что развитие инсулинорезистентности и избыточного
                    веса в любом возрасте ассоциированы различными
                    микроэлементными дефицитами.
                  </p> -->
                  <h2 class="h2-title-bg mt-5 mb-5">
                    Микронутриенты в составе
                  </h2>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>K</span>
                      Калий (300 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Регуляция водного, кислотного и электролитного баланса
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Участвует в регуляции уровня сахара в крови
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Помогает поддерживать щелочную среду в организме
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Mg</span>
                      Магний (200 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Энергетический метаболизм
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Синтез белков
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        <p>
                          Магний является неотъемлемой составной частью активных
                          центров ферментов.
                        </p>
                        <p class="mt-3">
                          Гликолиз — один из наиболее фундаментальных
                          биохимических путей углеводного метаболизма.
                        </p>
                        <p class="mt-3">
                          Дефицит магния неизбежно приведет к значительному
                          снижению активности по крайней мере шести из 10
                          гликолитических ферментов, способствуя развитию
                          глюкозотолерантности тканей.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Ca</span>
                      Кальций (695 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Участвует в процессах образования энергии
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Входит в состав многих ферментов, нормализующих обмен
                        веществ
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Na</span>
                      Натрий (550 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Основной внеклеточный ион, принимающий участие в
                        переносе воды, глюкозы крови
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Cu</span>
                      Медь (453 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Стимулирует усвоение белков и углеводов
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Cr</span>
                      Хром (60 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Участвует в регуляции уровня глюкозы крови, усиливая
                        действие инсулина
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        <p>
                          Участвует в регуляции углеводного и липидного обменов,
                          поддерживая нормальный уровень толерантности организма
                          к глюкозе.
                        </p>
                        <p class="mt-3">
                          Биологически активная форма хрома образует комплексное
                          соединение с инсулином, более активное, чем свободный
                          инсулин.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Mo</span>
                      Молибден (80 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Способствует метаболизму углеводов и жиров
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">
                    Супер Омега – мощная поддержка сердца, мозга и обмена
                    веществ
                  </h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/super-omega.png"
                        alt=""
                        class="img-super-omega"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p class="mt-3">
                        Омега-3 – это жизненно важные жирные кислоты, которые
                        организм не может вырабатывать самостоятельно, но без
                        них невозможно здоровое функционирование сердца, мозга,
                        суставов и иммунной системы.
                      </p>
                      <p class="mt-3">
                        Супер Омега – это не просто Омега-3, а расширенный
                        комплекс, который включает Омега-6, бета-глюканы и
                        усиленную антиоксидантную защиту.
                      </p>
                    </v-col>
                  </v-row>
                  <h3 class="h3-green mt-5">Содержание в 3 капсулах:</h3>
                  <ul class="ul-check">
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Омега-3 ПНЖК – 1260 мг (63% суточной нормы)
                      <ul class="pl-5">
                        <li class="pl-0">
                          EPA (эйкозапентаеновая кислота) – 630 мг (105%)
                        </li>
                        <li class="pl-0">
                          DHA (докозагексаеновая кислота) – 420 мг (60%)
                        </li>
                      </ul>
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Омега-6 ПНЖК
                      <ul class="pl-5">
                        <li class="pl-0">Линолевая кислота – 330 мг (33%)</li>
                        <li class="pl-0">
                          γ-линоленовая кислота – 70 мг (12%)
                        </li>
                      </ul>
                    </li>
                    <li>
                      <v-icon icon="mdi-check">mdi-check</v-icon>
                      Бета-глюканы – 114 мг (57%)
                    </li>
                  </ul>
                  <p class="mt-3"><strong>Чем он полезен? </strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Поддерживает здоровье сердца и сосудов – улучшает
                      текучесть крови, снижает уровень «плохого» холестерина и
                      устраняет воспаление сосудов.
                    </li>
                    <li>
                      Укрепляет нервную систему и мозг – способствует ясности
                      ума, снижает стресс и предотвращает возрастные изменения
                      мозга.
                    </li>
                    <li>
                      Нормализует липидный обмен и работу ЖКТ – помогает
                      правильно усваивать жиры, ускоряет их транспортировку и
                      выведение.
                    </li>
                    <li>
                      Усиливает детоксикацию и антиоксидантную защиту – снижает
                      окислительный стресс, поддерживает молодость клеток.
                    </li>
                  </ul>
                  <p class="mt-3"><strong>Это даёт тебе:</strong></p>
                  <ul class="mt-3 pl-5">
                    <li>
                      Сильное сердце и чистые сосуды – профилактика
                      атеросклероза и гипертонии.
                    </li>
                    <li>
                      Чистую кожу и гибкие суставы – устраняет воспаления и
                      укрепляет клеточные мембраны.
                    </li>
                    <li>
                      Ясный ум и крепкие нервы – помогает справляться со
                      стрессом и защищает мозг от переутомления.
                    </li>
                    <li>
                      Хорошее пищеварение и лёгкость в теле – нормализует обмен
                      жиров и поддерживает микрофлору кишечника.
                    </li>
                  </ul>
                  <h3 class="mt-3 text-center">
                    Чем Супер Омега отличается от обычных Омега-3?
                  </h3>
                  <ul class="mt-3 pl-5">
                    <li>
                      <strong>Оптимальные дозировки EPA и DHA</strong> для
                      максимальной пользы.
                    </li>
                    <li>
                      <strong
                        >Дополнительные Омега-6 (γ-линоленовая кислота)</strong
                      >
                      – поддержка кожи и суставов.
                    </li>
                    <li>
                      <strong>Бета-глюканы</strong> – усиление усвоения жиров и
                      антиоксидантная защита.
                    </li>
                  </ul>
                  <p class="mt-3">
                    <strong
                      >Супер Омега – твой источник молодости, энергии и
                      здоровья!</strong
                    >
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/wfoa7vw22c?web_component=true&seo=true" title="Супер Омега Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <h2 class="h2-title-bg mt-7 mb-5">Механизмы действия ПНЖК</h2>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/lipid-normalization.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Нормализация липидного обмена</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/intestine.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Повышенное выделение желчных кислот в кишечнике</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/increase-hdl.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Повышение уровня полезных липопротеидов высокой
                        плотности (ЛПВП)
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/lipid-transort.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Нормализация механизмов транспорта липидов по кровяному
                        руслу
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/gastric.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Улучшение абсорбции жиров в пищеварительном тракте</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/anti-oxidant.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Повышение антиоксидантных функций организма</p>
                    </v-col>
                  </v-row>
                  <div class="mt-7">
                    <h3 class="h3-green">
                      Масло примулы вечерней способствует
                    </h3>
                    <v-row class="row-icon">
                      <v-col cols="2" sm="3" class="col-img-center">
                        <img
                          src="/img/metabolism/primula.png"
                          alt=""
                          class="img-icon-avatar"
                        />
                      </v-col>
                      <v-col cols="10" sm="9" class="col-center">
                        <ul class="ul-check pl-0">
                          <li>
                            <v-icon icon="mdi-check">mdi-check</v-icon>
                            Снижению содержания холестерина в крови и
                            минимизации риска развития тромбов
                          </li>
                          <li>
                            <v-icon icon="mdi-check">mdi-check</v-icon>
                            Регулированию уровня сахара
                          </li>
                          <li>
                            <v-icon icon="mdi-check">mdi-check</v-icon>
                            Регуляции углеводного и липидного обмена
                          </li>
                        </ul>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Эксперты рекомендуют</h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/metabolism/zvereva.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Светлана Зверева</h3>
                      <p class="text-center">
                        Тренер по продукту LR, провизор, нутрициолог, специалист
                        в области технологии лекарств
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Мы часто слышим, что метаболизм очень важен для организма.
                    Но как заметить, что что-то пошло не так? Просто быть
                    внимательным к организму: тянет на сладенькое? Не можете
                    заснуть? Руки и пальцы мерзнут даже летом? Нерегулярный или
                    дискомфортный стул? Все это указывает на нарушение
                    протекания нормальных процессов в организме. Можно и нужно
                    пройти обследование, для исключения серьезных патологий, но
                    уже сейчас можно оказать поддержку организму и вернуть
                    нормальное течение обменных процессов. И сделать это очень
                    просто - не менее 3 месяцев использовать специализированный
                    набор Обмен веществ, который разносторонне поддержит системы
                    организма и наладит их работу.»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/metabolism/sherstobitova.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Татьяна Шерстобитова</h3>
                      <p class="text-center">
                        Кандидат технических наук, Доцент кафедры фармации и
                        химии УГМУ, Екатеринбург
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «С возрастом организму все больше требуется восполнять
                    ресурсы для активной жизни. Продукты комплекса: гриб Рейши,
                    минералы, синбиотики, ПНЖК, а также питьевая форма Алоэ Вера
                    позволяют продлить молодость изнутри. Наладить работу всего
                    организма 24/7 - вот задача набора Обмен веществ, который
                    окажет комплексную поддержку организму.»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/metabolism/dadali.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Владимир Дадали</h3>
                      <p class="text-center">
                        Доктор химических наук, профессор СЗГМУ им. И.И.
                        Мечникова, Доктор философии по натуральной медицине и
                        сертифицированный консультант по нутрициологии
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Необходимо постоянно поддерживать организм поступлением
                    качественных питательных веществ, потому что по данным ФИЦ
                    питания РАМН в рационе россиян из года в год выявляется
                    хронический дефицит основных микронутриентов, включающих 15
                    витаминов и около 30 минералов (макро- и микроэлементов).
                    Вот почему применение набора Обмен веществ в течение
                    длительного времени будет благотворно влиять на организм.
                    Кроме того, полезно время от времени сдавать анализ на
                    витамины Д и А, поскольку они жирорастворимые и крайне
                    важные для нашего организма.»
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Врачи рекомендуют</h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/metabolism/agalakov.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Алексей Агалаков</h3>
                      <p class="text-center">
                        Доктор превентивной медицины, абдоминальный хирург,
                        руководитель частной медицинской Клиники ДЕТОКСА.
                        Специалист по восстановлению работы кишечника, изучающий
                        Алоэ Вера и его влияние на организм человека
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Основная проблема в здоровье современного человека - это
                    метаболический синдром, или нарушение обмена веществ. Так
                    вот, специально разработанный комплекс "Обмен веществ"
                    призван решить задачу восстановления здоровья и успешно её
                    решает. В этом мы, доктора, уже убедились на практике.
                    Только нужно помнить, что биохимические процессы приходят в
                    норму в течение 3-6 месяцев, и этот эффект нужно ещё и
                    закрепить. Поэтому, просто уделите время своему организму и
                    он восстановится!»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/metabolism/ravilova.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Альбина Равилова</h3>
                      <p class="text-center">Врач, педиатр, инфекционист</p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Набор Обмен веществ при длительном приеме дает восстановить
                    правильное функционирование всех органов и систем, а также
                    помогает организму восполнять дефициты витаминов, минералов
                    и жирных кислот, улучшает в первую очередь пищеварение и,
                    благодаря этому, меняется качество жизни.
                  </p>
                  <p class="font-italic mt-3">
                    Также я вижу, как он дает возможность жить без жестких диет
                    и ограничений, потому что даже после нарушения питания,
                    принимая продукцию, мы получаем хорошее самочувствие, не
                    будет тяжести , вздутия, изжоги, гниения пищи, а еще,
                    принимая набор минимум 3 месяца, вы сможете легко
                    просыпаться по утрам без будильника, быть активным, уйдёт
                    тревожность, улучшится настроение!»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/metabolism/zvezdina.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Татьяна Звездина</h3>
                      <p class="text-center">
                        Врач педиатр, специалист по нелекарственным методам
                        оздоровления
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «В основе нашего здоровья лежит качественный обмен веществ.
                    Это тысячи биохимических реакций, которые протекают в каждой
                    клетке организма. Наводить порядок в организме я рекомендую
                    с набором Обмен веществ. И не важно, есть у человека жалобы
                    или он чувствует себя прекрасно.
                  </p>
                  <p class="font-italic mt-3">
                    Гель Алоэ Вера и Пробаланс обеспечат очищение и питание
                    клеток. Без Омега-3 жирных кислот клетки не могут правильно
                    взаимодействовать. Гриб Рейши - адаптоген, повышающий
                    сопротивляемость к неблагоприятным условиям внешней среды.
                    Про 12 поддерживает микробиом, от состояния которого зависит
                    не только работа нашего ЖКТ, состояние иммунитета, но даже
                    настроение, сон, успехи в делах. Следует понимать, что все
                    здоровые перемены медленные. На нормализацию обменных
                    процессов нужно минимум 3-6 месяцев. А чем старше мы
                    становимся, тем длительнее должна быть поддержка организма.»
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/metabolism/usainova.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Лиана Усаинова</h3>
                      <p class="text-center">
                        Врач-эндокринолог, диетолог и натуропат, автор книги
                        «Еда как лекарство»
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    «Набор Обмен веществ мне нравится тем, что он безопасно
                    улучшает гормональный фон, повышает бодрость,
                    внимательность, улучшает всасывание витаминов и минералов
                    через кишечник. Иммунитет балансируется. Нервная система
                    стабилизируется. Мне, как врачу, этот набор – хороший
                    помощник.
                  </p>
                  <p class="font-italic mt-3">
                    Я участвовала в исследованиях по выявлению дефицитов у
                    россиян. Выводы очень печальные. Почва истощена. Нам
                    недостает витаминов и минералов из пищи. Поэтому
                    дополнительный прием витаминов и минералов должен быть
                    непрерывным! И поэтому 3 месяца – это самый минимум, чтобы
                    организм обновился.»
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Отзывы</h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/antiage/filippoval.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                      <!-- <img
                        src="/img/antiage/filippovan.jpg"
                        alt=""
                        class="avatar-expert-img"
                      /> -->
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Алексей и Наталья Филипповы</h3>
                      <p class="font-italic mt-3">
                        Когда я пришёл в LR, мой вес был 92 кг. Спустя время
                        работы с компанией и использования продуктов, вес
                        стабилизировался в диапазоне 78-80 кг. Если посчитать —
                        это минус 12-14 кг, и это без жёстких диет и
                        изнурительных тренировок.
                      </p>
                      <p class="font-italic mt-3">
                        Но самое крутое — это результат моей супруги Натальи.
                        Она осознанно подошла к процессу, активно использовала
                        продукты LR для баланса веса, и её итог — минус 17 кг.
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">
                    Итого: вместе мы сбросили 31 кг! 😳🔥
                  </p>
                  <p class="font-italic mt-3">
                    Это не просто цифры, а доказательство того, что качественные
                    продукты и системный подход реально работают!
                  </p>
                  <v-divider
                    class="border-divider mt-10 mx-auto"
                    color="#9acb9e"
                    length="170px"
                    thickness="2"
                  />
                  <v-divider
                    class="border-divider mt-1 mb-8 mx-auto"
                    color="#9acb9e"
                    length="100px"
                    thickness="2"
                  />
                  <v-row class="mt-3">
                    <v-col cols="12" sm="2" class="text-center">
                      <img
                        src="/img/antiage/sidorovivan.jpg"
                        alt=""
                        class="avatar-expert-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="10">
                      <h3 class="h3-green">Иван Сидоров</h3>
                      <p class="text-center">
                        Мой путь с продуктами LR: от боли и усталости к энергии
                        и здоровью
                      </p>
                    </v-col>
                  </v-row>
                  <p class="font-italic mt-3">До LR (35 лет)</p>
                  <ul class="font-italic mt-3 pl-5">
                    <li>Вес – более 115 кг.</li>
                    <li>
                      Головные боли еженедельно более 12 лет (даже когда был
                      стройным).
                    </li>
                    <li>С детства боли в ногах при смене погоды.</li>
                    <li>Сильные головные боли при усталости</li>
                    <li>
                      Простуды 5+ раз в год, переносил их «на ногах» из-за
                      работы.
                    </li>
                    <li>
                      Спорт казался невозможным – задыхался с первых минут.
                    </li>
                    <li>
                      Думал, что это нормально – «ведь я старею»… и в 35 лет уже
                      смирился с этим.
                    </li>
                  </ul>
                  <p class="font-italic mt-3">С LR (сейчас 46)</p>
                  <ul class="font-italic mt-3 pl-5">
                    <li>Вес – стабильно 88–89 кг</li>
                    <li>
                      Головные боли? Забыл, что это такое! Уже 9 лет без
                      таблеток.
                    </li>
                    <li>Боли в ногах исчезли – даже при смене погоды.</li>
                    <li>
                      Могу спать всего 5,5–6 часов и чувствовать себя бодрым.
                    </li>
                    <li>
                      Бегаю по утрам, максимальная дистанция 21 км – и у меня
                      хватает на это энергии!
                    </li>
                  </ul>
                  <p class="font-italic mt-3">
                    Теперь ты понимаешь, почему я выбираю продукцию LR?
                  </p>
                </div>
              </div>
              <div class="text-center mt-10" id="contacts">
                <h3 class="h3-contacts">Свяжитесь со мной, чтобы:</h3>
                <ul class="ul-check-set">
                  <li>получить максимальную скидку и выгодные условия</li>
                  <li>подобрать индивидуальный набор для вас</li>
                  <li>получить подробную консультацию по набору</li>
                </ul>
                <!-- <v-icon
                  icon="mdi-chevron-triple-down"
                  size="x-large"
                  color="#94b894"
                  class="mt-5"
                ></v-icon> -->
                <div class="mt-12 mb-7">
                  <div class="d-flex grow flex-wrap">
                        <div
                            class="contact-avatar v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </div>
              </div>
            </div>
          </v-card>
      </v-responsive>
    </v-container>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Metabolism",
    data: () => ({
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Набор Обмен веществ от LR Health & Beauty',
        meta: [
            { vmid: 'description', property: 'description', content: 'Комплексное решение для нормализации обмена веществ, работы ЖКТ, восстановления работы внутренних органов и защиты от простудных заболеваний' },
            { vmid: 'og:title', property: 'og:title', content: 'Набор Обмен веществ от LR Health & Beauty' },
            { vmid: 'og:description', property: 'og:description', content: 'Комплексное решение для нормализации обмена веществ, работы ЖКТ, восстановления работы внутренних органов и защиты от простудных заболеваний' },
            { vmid: 'og:image', property: 'og:image', content: '' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'Metabolism', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }

        if (this.$cookies.isKey('lifeplus_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('lifeplus_cookie', 'accept', '12m')
        },
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: raleway-b;
  src: url("/fonts/raleway-black.woff2");
  font-weight: 300;
}
.app-fill-height {
  font-family: Roboto,sans-serif;
  font-size: 16px !important;
}
ul li {
  margin-bottom: 5px;
}
.contact-avatar {
  top:-30px;
  bottom:0;
}
.page-box-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 10px;
}
.page-box-content {
  background-color: #fff;
  padding: 10px 5px 20px;
  border-radius: 3px;
}
.h2-green {
  font-family: raleway-b;
  font-size: calc(24px + (36 - 24) * ((100vw - 320px) / (800 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #94b894;
}
.box-border-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 2px;
  border-radius: 5px;
}
.ul-check-set {
  list-style-type: none;
}
.ul-check-set li {
  position: relative;
  margin-bottom: 5px;
}
.box-border-content {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.img-top-box {
  margin: 20px 0;
}
.germany-top {
  position: absolute;
  width: calc(40px + (70 - 40) * ((100vw - 320px) / (800 - 320)));
  right: 43%;
}
.metabolism-honey-peach {
  max-width: 700px;
  width: 100%;
}
.p-main {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 0 15px;
}
.h2-title-bg {
  text-align: center;
  font-family: raleway-b;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  border-radius: 4px;
}
.gel-avatar,
.reishi-omega-avatar,
.pro-balance-avatar,
.pro12-avatar {
  text-align: center;
  margin-top: 20px;
}
.gel-avatar img {
  width: 80%;
}
.reishi-omega-avatar img {
  width: 78%;
}
.pro-balance-avatar img {
  width: 73%;
}
.pro12-avatar img {
  width: 72.6%;
}
.synergy-box {
  position: relative;
}
.synergy-box-mobile {
  display: none;
}
.synergy-box img {
  position: absolute;
  width: 80px;
  left: 43%;
}
.h3-col-title {
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
}
.p-col {
  text-align: center;
  margin-bottom: 20px;
}
.aloe-leaf-iasc {
  width: 100%;
}
.avatar-expert-img {
  border-radius: 50%;
  width: 100%;
  max-width: 120px;
}
.h3-green {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.title-orange {
  text-align: center;
  font-family: raleway-b;
  color: #eab355;
  text-transform: uppercase;
}
.h4-title {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.av-honey,
.av-peach {
  width: 40px;
}
.h4-honey {
  color: #ffbc02;
}
.h4-peach {
  color: #ff8902;
}
.h4-green {
  color: #94b894;
}
.av-leaf-avatar,
.honey-avatar {
  width: 80px;
  height: 80px;
  padding: 3px;
  border-radius: 50%;
  border: 3px solid #94b894;
}
.reishi-img,
.pro12-img,
.img-pro-balance,
.img-super-omega {
  width: 60%;
}
.img-icon-avatar {
  width: 40%;
  border-radius: 50%;
  border: 3px solid #94b894;
  padding: 3px;
}
.col-img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-center {
  align-self: center;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.img-avatar {
  width: 80%;
  border-radius: 50%;
  border: 3px solid #94b894;
}
.ul-check {
  list-style-type: none;
  margin-top: 10px;
}
.ul-check li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 30px;
}
.ul-check li .v-icon {
  color: #94b894;
  position: absolute;
  left: 0;
}
.h4-icon {
  text-align: center;
  margin-bottom: 10px;
  color: #9dc9b9;
}
.h4-icon span {
  background: #9dc9b9;
  color: #fff;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.micro-div {
  margin-bottom: 25px;
}
.ul-dot {
  list-style-type: none;
}
.ul-dot li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 7px;
}
.ul-dot li .v-icon {
  color: #94b894;
  position: absolute;
  left: 0;
  top: -4px;
}
.h3-contacts {
  text-transform: uppercase;
  color: #94b894;
  margin-bottom: 10px;
}
@media (min-width: 800px) {
  .h2-green {
    font-size: 36px;
  }
  .germany-top {
    width: 70px;
  }
}
@media (max-width: 680px) {
  .reishi-img,
  .pro12-img,
  .img-pro-balance,
  .img-super-omega {
    width: 80%;
  }
  .img-icon-avatar {
    width: 60%;
  }
  .img-avatar {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .synergy-box {
    display: none;
  }
  .synergy-box-mobile {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .synergy-box-mobile img {
    width: 80px;
  }
  .aloe-leaf-iasc {
    width: 150px;
  }
  .av-leaf-avatar,
  .honey-avatar {
    width: 60px;
    height: 60px;
  }
  .reishi-img,
  .pro12-img,
  .img-pro-balance,
  .img-super-omega {
    width: 25%;
  }
  .row-icon {
    margin-bottom: 10px;
  }
  .col-img-center {
    padding: 0;
  }
  .col-center {
    font-size: 16px;
  }
  .img-icon-avatar {
    width: 60px;
    height: 60px;
  }
  .img-avatar {
    width: 40%;
  }
}
@media (max-width: 480px) {
  .av-leaf-avatar,
  .honey-avatar {
    width: 50px;
    height: 50px;
  }
  .img-icon-avatar {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 430px) {
  .img-icon-avatar {
    width: 45px;
    height: 45px;
  }
  .col-center {
    font-size: 14px;
  }
  .row-icon {
    margin-left: -7px;
  }
}
@media (max-width: 360px) {
  .av-leaf-avatar,
  .honey-avatar {
    width: 40px;
    height: 40px;
  }
}
</style>
